import styles from "./Button.module.scss";
import React from "react";

interface ButtonProps {
  label: string;
  mode?: "primary" | "primaryOutlined" | "primaryOutlinedNarrow" | "secondary" | "secondaryOutlined" | "secondaryOutlinedNarrow";
  onClick?: Function;
  disabled?: boolean;
}

export const Button: React.FunctionComponent<ButtonProps> = (props) => {
  const modeParser = (mode: string) => {
    return styles[mode] ? styles[mode] : styles["primary"];
  };

  return (
    <button
      type="button"
      onClick={() => {
        if (props.onClick) props.onClick();
      }}
      className={[modeParser(props.mode), styles.button].join(" ")}
      disabled={props.disabled}
    >
      {props.label}
    </button>
  );
};
