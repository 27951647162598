import React from "react";
import {Badge} from "@vacasa/react-components-lib";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {selectFetcherReservation} from "../../../../store/fetcherSlice";
import style from "./EmailHeader.module.scss";
import {PillsInput, PillsInputCallbackType} from "../../inputs/pills-input/pills-input";
import {setRecipients, setError} from "../../../../store/emailSlice";

export const EmailHeader: React.FC<any> = () => {
  const dispatch = useAppDispatch();

  const reservation = useAppSelector(selectFetcherReservation);
  const confirmation_code = reservation?.attributes?.confirmation_code;

  const handleRecipientEmails = (e: PillsInputCallbackType) => {
    dispatch(setRecipients(e.items));
    dispatch(setError(e.error));
  };

  return (
    <div className={style.headerEmail}>
      <div className={style.emailContacts}>
        <span className={style.titleEmails}>To:</span>
        <PillsInput
          pillComponent={Badge}
          pillTextField={"text"}
          pillCallbackDeleteField={"handleClose"}
          initialPillsProps={
            reservation?.attributes?.guestInformation?.email
              ? [
                  {
                    disabled: true,
                    closeButton: false,
                    isPill: true,
                    variant: "default",
                    outline: false,
                    text: reservation?.attributes?.guestInformation?.email,
                  },
                ]
              : []
          }
          pillValidProps={{
            disabled: false,
            closeButton: true,
            isPill: true,
            variant: "default",
            outline: false,
          }}
          pillInvalidProps={{
            disabled: false,
            closeButton: true,
            isPill: true,
            variant: "error",
            outline: false,
          }}
          allowDuplicated={false}
          typeValidator={"email"}
          callbackState={handleRecipientEmails}
          inputPlaceholder={"add recipient"}
        />
      </div>
      <p>Subject: Vacasa Reservation Cancellation: {confirmation_code}</p>
    </div>
  );
};
