import React, {useState} from "react";
import styles from "./EmailBody.module.scss";
import logo from "../../../../assets/images/vacasa_identity_lockup_horizontal_blue.svg";
import moment from "moment";
import cx from "classnames";
import {FrontendConfiguration} from "@common/configuration";
import {Select} from "../../inputs/select/Select";
import {refundTypeData, refundTypeMessage, refundAmountTypes} from "../../../../data/staticData";
import {RefundType} from "@common/typing";
import {toLocalString} from "../../../../utils";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {
  setFSCData,
  setFSCAmount,
  setRefundAmount,
  setRefundType,
  selectFinancesRefundFscHas,
  selectFinancesRefundCreditCardsAmount,
  selectFinancesRefundCreditCardsData,
  selectFinancesRefundCheckAmount,
  selectFinancesRefundAmount,
  selectFinancesPaid,
  selectFinancesCurrencyCode,
  selectFinancesRefundFcsData,
  selectFinancesRefundFscAmount,
} from "../../../../store/financesSlice";
import {setBody, selectEmailBody} from "../../../../store/emailSlice";
import {selectFetcherUnit, selectFetcherReservation} from "../../../../store/fetcherSlice";

export const EmailBody: React.FC<any> = () => {
  const dispatch = useAppDispatch();

  const reservation = useAppSelector(selectFetcherReservation);
  const unit = useAppSelector(selectFetcherUnit);
  const hasFSCAmount = useAppSelector(selectFinancesRefundFscHas);
  const creditCardsRefundAmount = useAppSelector(selectFinancesRefundCreditCardsAmount);
  const creditCardsRefundData = useAppSelector(selectFinancesRefundCreditCardsData);
  const checkRefundAmount = useAppSelector(selectFinancesRefundCheckAmount);
  const totalRefund = useAppSelector(selectFinancesRefundAmount);
  const totalPaid = useAppSelector(selectFinancesPaid);
  const currencyCode = useAppSelector(selectFinancesCurrencyCode);
  const fscRefundData = useAppSelector(selectFinancesRefundFcsData);
  const fscRefundAmount = useAppSelector(selectFinancesRefundFscAmount);
  const emailBody = useAppSelector(selectEmailBody);

  const firstNight = reservation?.attributes?.first_night;
  const lastNight = reservation?.attributes?.last_night;

  const [selected, setSelected] = useState("DEFAULT");
  const onSelectChange = ({id, text}) => {
    setSelected(id);
    dispatch(setRefundType(id));
  };

  const handleFSCAmountChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const fscString = evt.target.value
      .replace(/\n/gi, " ")
      .replace(/[^0-9.]/g, "")
      .replace(/\.{2,}/g, ".")
      .replace(/^0*([^0]\d*\.\d{1,2}).*/g, "$1");
    const fscAmount = Number(Number(fscString !== "" ? fscString : 0).toFixed(2));
    dispatch(setFSCAmount(fscAmount));
    dispatch(setFSCData(fscAmount > 0 ? [{FSC_AMOUNT: fscAmount, TARGET_RES: ""}] : []));
    dispatch(setRefundAmount(Number((creditCardsRefundAmount + fscAmount + checkRefundAmount).toFixed(2))));
  };

  const handleChange = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setBody(evt.target.value.replace(/\n/gi, " ")));
  };

  const filterRefundTypeOptions = (refundTypes) => {
    return refundTypes
      .filter((type) => (totalRefund > 0 ? type.id !== RefundType.NO_REFUND : true))
      .filter((type) => (totalPaid > 0 ? type.id !== RefundType.NO_FUNDS_COLLECTED : true));
  };

  return (
    <div className={styles.bodyEmail}>
      <div className={styles.emailContent}>
        <img src={logo} alt={"vacasa horizontal logo"} className={styles.logo} />
        <div className={styles.title}>Confirmation of reservation cancellation</div>
        <div className={styles.subtitle}>Your reservation in {unit?.address?.city} has been successfully cancelled</div>
        <table>
          <tbody className={styles.table}>
            <tr>
              <td className={styles.unitName}>{unit?.name}</td>
              <td className={cx(styles.reservationCode, styles.tableRightColumn)}>
                Reservation: {reservation?.attributes?.confirmation_code ?? reservation?.attributes?.legacy_reservation_id}
              </td>
            </tr>
            <tr>
              <td>
                <span className={styles.dateLabel}>Check in</span>
                <br />
                {moment(firstNight).format("MMM DD, YYYY")}
              </td>
              <td className={styles.tableRightColumn}>
                <span className={styles.dateLabel}>Check out</span>
                <br />
                {moment(lastNight).add(1, "days").format("MMM DD, YYYY")}
              </td>
            </tr>
          </tbody>
        </table>
        <strong>Optional note to Guest:</strong>
        <textarea className={styles.textAreaNote} onChange={handleChange} value={emailBody} />
        <div className={styles.select}>
          <Select label={"Select refund type"} options={filterRefundTypeOptions(refundTypeData)} onChange={onSelectChange} value={selected} />
        </div>

        <div className={styles.emailTextMessage}>
          {refundTypeMessage[selected] && <p>{refundTypeMessage[selected]}</p>}

          {refundAmountTypes.indexOf(selected) !== -1 && (
            <div>
              <p>Your refund of {toLocalString(totalRefund, currencyCode)} will be sent as follows:</p>

              <ul>
                {creditCardsRefundData?.map((c, index) => (
                  <li key={index}>
                    {toLocalString(c?.CARD_AMOUNT, currencyCode)} on your credit card ending in <strong>{c?.CARD_NUMBER}</strong>.
                  </li>
                ))}
                {checkRefundAmount > 0 && <li>{toLocalString(checkRefundAmount, currencyCode)} by check.</li>}
                {hasFSCAmount ? (
                  fscRefundData?.map((e, index) => (
                    <li key={index}>
                      {toLocalString(e?.FSC_AMOUNT, currencyCode)}{" "}
                      {e?.TARGET_RES ? `transfer to your new reservation ${e?.TARGET_RES}.` : "by Future Stay Credit."}
                    </li>
                  ))
                ) : (
                  <li>
                    $
                    <input
                      className={styles.fscInput}
                      placeholder={"FSC Amount"}
                      type={"number"}
                      value={fscRefundAmount > 0 ? fscRefundAmount : ""}
                      onChange={handleFSCAmountChange}
                    />{" "}
                    by Future Stay Credit.
                  </li>
                )}
              </ul>
              <div>
                <p>Refund time depends on your payment method and may take up to 10 business days.</p>
                <p>
                  Please note that all transactions are processed in their original booked currency. (<strong>{currencyCode}</strong>)
                </p>
              </div>
            </div>
          )}
        </div>

        <br />
        <p>
          Have questions? Please visit our{" "}
          <a target={"_blank"} href={FrontendConfiguration.getSupportAndContactLink()}>
            Support and Contact page.
          </a>
        </p>
      </div>
    </div>
  );
};
