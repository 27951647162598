import React, {useState} from "react";
import {Button} from "../../common-components/inputs/button/Button";
import {EmailContainer} from "../../common-components/email/email-container/EmailContainer";
import styles from "./EmailStep.module.scss";
import {Modal} from "@vacasa/react-components-lib";
import {ModalImage} from "../../common-components/modal/modal-image/ModalImage";
import image from "../../../assets/images/early_check_in.png";
import * as Sentry from "@sentry/react";
import {Image} from "@vacasa/react-components-lib";
import {refundAmountTypes} from "../../../data/staticData";
import {FrontendConfiguration} from "@common/configuration";
import {useAppDispatch, useAppSelector} from "../../../store/store";
import {selectFinancesRefundType, selectFinancesRefundAmount} from "../../../store/financesSlice";
import {selectFetcherReservation} from "../../../store/fetcherSlice";
import {
  setSendingFalse,
  setSuccessFalse,
  sendCancellationEmail,
  selectEmailError,
  selectEmailRecipients,
  selectEmailSending,
  selectEmailSuccess,
} from "../../../store/emailSlice";

export const EmailStep: React.FunctionComponent<any> = ({data}) => {
  const dispatch = useAppDispatch();

  const reservation = useAppSelector(selectFetcherReservation);
  const refundType = useAppSelector(selectFinancesRefundType);
  const totalRefund = useAppSelector(selectFinancesRefundAmount);
  const emailRecipients = useAppSelector(selectEmailRecipients);
  const emailError = useAppSelector(selectEmailError);
  const emailSending = useAppSelector(selectEmailSending);
  const emailSuccess = useAppSelector(selectEmailSuccess);

  const [hasRegularError, setHasRegularError] = useState<boolean>(false);
  const [hasNoRefundError, setHasNoRefundError] = useState<boolean>(false);
  const [errorsObject, setErrorsObject] = useState({
    title: "",
    description: "",
  });
  const {url} = FrontendConfiguration.adminConfig();

  async function handleClick() {
    try {
      if (!refundType) {
        setHasRegularError(true);
        setErrorsObject({
          title: "No Refund Type specified",
          description: "You must specify a refund type",
        });
      } else if (refundAmountTypes.indexOf(refundType) !== -1 && totalRefund === 0) {
        setHasNoRefundError(true);
      } else if (emailRecipients.length === 0) {
        setHasRegularError(true);
        setErrorsObject({
          title: "Empty recipients",
          description: "You must add recipients to send the email",
        });
      } else if (emailError) {
        setHasRegularError(true);
        setErrorsObject({
          title: "Recipient with invalid format",
          description: "Some recipient has an invalid format. You must correct it before sending the email",
        });
      } else {
        dispatch(sendCancellationEmail());

        setTimeout(() => {
          handleClickAdminRedirect();
        }, 2000);
      }
    } catch (error) {
      setHasRegularError(true);
      setErrorsObject({
        title: "An error has occurred",
        description: "Whoops! It was not possible to send the email.",
      });
      error && Sentry.captureException(error);
    }
  }

  const handleClickAdminRedirect = () => {
    window.top.location.href = `${url}/admin/dashboard/reservation/${reservation.attributes.legacy_reservation_id}`;
  };

  const ErrorSendingEmail = () => {
    const {title, description} = errorsObject;
    return (
      <Modal setShowModal={setHasRegularError} showModal={hasRegularError} size="small">
        <div className={styles.modalContent}>
          <ModalImage image={image} />
          <h3 className={styles.titleMessage}>{title}</h3>
          <p className={styles.descriptionMessage}>{description}</p>
        </div>
      </Modal>
    );
  };

  const ErrorNoRefund = () => {
    return (
      <Modal setShowModal={setHasNoRefundError} showModal={hasNoRefundError} size="small">
        <div className={styles.modalContent}>
          <ModalImage image={image} />
          <h3 className={styles.titleMessage}>No Refund Amount</h3>
          <p className={styles.descriptionMessage}>
            This canceled reservation currently has a refund of $0. If there should be a refund to the guest, then go back to the reservation and
            update the refund before sending the cancel email.{" "}
            <a href={`${url}/admin/dashboard/reservation/${reservation.attributes.legacy_reservation_id}`}>Go to Reservation</a>
          </p>
          <p className={styles.descriptionMessage}>If there is no refund, then select one of these options on this cancel email page:</p>
          <ul>
            <li>Not refund eligible</li>
            <li>No payment received</li>
          </ul>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.box}>
          <h2 className={"boxTitle"}>Send confirmation email</h2>
          <p>This reservation is being cancelled. Send the guest a confirmation of their cancellation.</p>

          <EmailContainer data={data} title="Email Preview" />
        </div>

        <div className={styles.btnBox}>
          <Button label={"Send Email"} mode="primary" onClick={handleClick} />
        </div>
      </div>
      <Modal showModal={emailSending} setShowModal={() => dispatch(setSendingFalse())} size={"small"} canExit={false}>
        <div className={styles.sendingMessage}>
          <Image.Spinner style={{display: "flex"}} width={50} />
          <span className={styles.message}>Sending email...</span>
        </div>
      </Modal>
      <Modal showModal={emailSuccess} setShowModal={() => dispatch(setSuccessFalse())} size={"small"}>
        <div className={styles.sendingMessage}>
          <span className={styles.message}>Email sent successfully</span>
        </div>
      </Modal>
      {hasRegularError && <ErrorSendingEmail />}
      {hasNoRefundError && <ErrorNoRefund />}
    </>
  );
};
