import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {NotificationImplicitService} from "@common/services";
import {NotificationReplacementsItem} from "@common/typing";
import {EmailReplacements} from "@common/utils";
import {EventService} from "../services";

const notificationService = NotificationImplicitService.getInstance();

export const sendCancellationEmail = createAsyncThunk("email/send", async (_, {getState}) => {
    const state = getState() as any;
    const recipients = state.email.recipients;
    const reservation = state.fetcher.reservation;
    const emailBody = state.email.body;
    const finances = state.finances;
    const unit = state.fetcher.unit;

    const payload = {
        to: recipients.map((r) => ({
            address: r,
            name:
                r === reservation.attributes?.guestInformation?.address
                    ? `${reservation.attributes?.guestInformation?.first_name} ${reservation.attributes?.guestInformation?.last_name}`
                    : r,
            type: "to",
        })),
        note: emailBody,
        reservationId: reservation.attributes.legacy_reservation_id,
        finances,
    };
    try {
        let replacements: NotificationReplacementsItem[] = EmailReplacements.createCancellationV2Replacements({
            data: payload,
            reservation,
            unit,
        });

        await notificationService.sendCancellationEmailV2(payload, replacements);

        EventService.dispatchCancellationEmailSuccess(payload);

        return true;
    } catch (error) {
        EventService.dispatchCancellationEmailError(payload, error.message);
    }
});

interface EmailState {
    recipients: Array<string>;
    body: string;
    sending: boolean;
    error: boolean;
    success: boolean;
}

const initialState: EmailState = {
    recipients: [],
    body: "",
    sending: false,
    error: false,
    success: false,
};

const emailSlice = createSlice({
    name: "email",
    initialState,
    reducers: {
        setRecipients: (state, {payload}) => {
            state.recipients = payload;
        },
        setBody: (state, {payload}) => {
            state.body = payload;
        },
        setError: (state, {payload}) => {
            state.error = payload;
        },
        setSendingFalse: (state) => {
            state.sending = false;
        },
        setSuccessFalse: (state) => {
            state.success = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendCancellationEmail.pending, (state) => {
                state.sending = true;
                state.success = false;
            })
            .addCase(sendCancellationEmail.fulfilled, (state) => {
                state.sending = false;
                state.success = true;
            })
            .addCase(sendCancellationEmail.rejected, (state) => {
                state.sending = false;
                state.success = false;
            });
    },
});

export const selectEmailBody = (state: RootState) => state.email.body;
export const selectEmailRecipients = (state: RootState) => state.email.recipients;
export const selectEmailError = (state: RootState) => state.email.error;
export const selectEmailSending = (state: RootState) => state.email.sending;
export const selectEmailSuccess = (state: RootState) => state.email.success;

export const {setSendingFalse, setSuccessFalse, setBody, setError, setRecipients} = emailSlice.actions;

export default emailSlice.reducer;
