export const refundTypeData = [
    {id: "DEFAULT", text: "Select refund type", disabled: true, hidden: true},
    {id: "PARTIAL_REFUND_STANDARD", text: "Standard partial refund"},
    {id: "PARTIAL_REFUND_NON_STANDARD", text: "Non-standard partial refund"},
    {id: "FULL_REFUND", text: "Full refund"},
    {id: "NO_FUNDS_COLLECTED", text: "No payment received"},
    {id: "NO_REFUND", text: "Not refund eligible"},
];

export const refundTypeMessage = {
    DEFAULT: "",
    PARTIAL_REFUND_STANDARD: "You will receive a full refund for the amount you paid, minus Vacasa's booking fee and associated taxes.",
    PARTIAL_REFUND_NON_STANDARD: "",
    FULL_REFUND: "You will receive a full refund for the amount you paid.",
    NO_FUNDS_COLLECTED: "This trip was not eligible for a refund because no funds were collected.",
    NO_REFUND: "This trip was not eligible for a refund.",
};

export const refundAmountTypes = ["FULL_REFUND", "PARTIAL_REFUND_STANDARD", "PARTIAL_REFUND_NON_STANDARD"];
