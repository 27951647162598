import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {FrontendConfiguration} from "@common/configuration";
import {ImplicitFlow, JwtValidator, ThemeProvider} from "@vacasa/react-components-lib";
import DefaultTheme from "@vacasa/react-components-lib/src/themes/default";
import CancellationEmail from "./CancellationEmail";
import {Provider} from "react-redux";
import {store} from "./store/store";
import {datadogLogs, LogsEvent} from "@datadog/browser-logs";
import {obfuscateTokenFromUrl} from "@common/utils";

const params = new URLSearchParams(window.location.search);

const environment = FrontendConfiguration.environment();
let token: string = params.get("token");
const adminConfig = FrontendConfiguration.adminConfig();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

Sentry.init({
  dsn: FrontendConfiguration.getSentryDsn(),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.01,
});

environment !== "local" &&
  datadogLogs.init({
    clientToken: FrontendConfiguration.getDataDogToken(),
    site: FrontendConfiguration.dataDogUrl(),
    forwardErrorsToLogs: true,
    env: FrontendConfiguration.datadogGlobalContext().env,
    sampleRate: 100,
    beforeSend: (log: LogsEvent) => {
      log.view.url = obfuscateTokenFromUrl(log.view.url);
    },
  });
datadogLogs.setLoggerGlobalContext(FrontendConfiguration.datadogCustomGlobalContext(FrontendConfiguration.DD_RESERVATION_MANAGEMENT_UI));

// if loaded, we remove the reference to force refreshed cache
sessionStorage.removeItem("cache-force-refreshed");

const MainApp = () => (
  <ImplicitFlow {...FrontendConfiguration.implicitFlowConfig()}>
    <Provider store={store}>
      <CancellationEmail />
    </Provider>
  </ImplicitFlow>
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={DefaultTheme}>
      {environment === FrontendConfiguration.LOCAL ? (
        <MainApp />
      ) : (
        <JwtValidator jwt={token} base64Key={adminConfig.publicKey} storageName={FrontendConfiguration.tokenStorageName}>
          <MainApp />
        </JwtValidator>
      )}
    </ThemeProvider>
  </React.StrictMode>
);
