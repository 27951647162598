import {EventService as BaseEventService} from "@common/services";
import {datadogLogs} from "@datadog/browser-logs";
import {EventSources, EventTypes, obfuscateTo} from "@common/utils";
import {CancelNotificationPayloadV2} from "@common/typing";

export class EventService extends BaseEventService {
    /**
     * Emit event when cancellation email success
     * @param payload
     */
    public static dispatchCancellationEmailSuccess(payload: CancelNotificationPayloadV2) {
        payload.to = obfuscateTo(payload.to);
        EventService.dispatch(datadogLogs, {
            title: "Cancellation Email Send Success",
            message: `Reservation Legacy ID: ${payload.reservationId} `,
            type: EventTypes.CANCELLATION_EMAIL_SEND_SUCCESS,
            source: EventSources.UI,
            level: EventService.INFO_LEVEL,
            data: {id: payload.reservationId, payload},
        });
    }

    /**
     * Emit event when cancellation email fails
     * @param payload
     * @param error
     */
    public static dispatchCancellationEmailError(payload: CancelNotificationPayloadV2, error: string) {
        payload.to = obfuscateTo(payload.to);
        EventService.dispatch(datadogLogs, {
            title: "Cancellation Email Send Fail",
            message: `Reservation Legacy ID: ${payload.reservationId} `,
            type: EventTypes.CANCELLATION_EMAIL_SEND_FAIL,
            source: EventSources.UI,
            level: EventService.ERROR_LEVEL,
            data: {id: payload.reservationId, error},
        });
    }

    /**
     * Emit event when fetch reservation fails
     * @param id
     * @param error
     */
    public static dispatchFetchReservationError(id: string, error: string) {
        EventService.dispatch(datadogLogs, {
            title: "Cancellation Email Fetch Reservation Fail",
            message: `Reservation Legacy ID: ${id} `,
            type: EventTypes.CANCELLATION_EMAIL_FETCH_RESERVATION_FAIL,
            source: EventSources.UI,
            level: EventService.ERROR_LEVEL,
            data: {id, error},
        });
    }

    /**
     * Emit event when fetch unit fails
     * @param uuid
     * @param error
     */
    public static dispatchFetchUnitError(uuid: string, error: string) {
        EventService.dispatch(datadogLogs, {
            title: "Cancellation Email Fetch Unit Fail",
            message: `Unit UUID: ${uuid} `,
            type: EventTypes.CANCELLATION_EMAIL_FETCH_UNIT_FAIL,
            source: EventSources.UI,
            level: EventService.ERROR_LEVEL,
            data: {uuid, error},
        });
    }

    /**
     * Emit event when fetch payments summary fails
     * @param id
     * @param error
     */
    public static dispatchFetchPaymentsSummaryError(id: string, error: string) {
        EventService.dispatch(datadogLogs, {
            title: "Cancellation Email Fetch Payments Summary Fail",
            message: `Reservation Legacy ID: ${id} `,
            type: EventTypes.CANCELLATION_EMAIL_FETCH_PAYMENTS_SUMMARY_FAIL,
            source: EventSources.UI,
            level: EventService.ERROR_LEVEL,
            data: {id, error},
        });
    }

    /**
     * Emit event when fetch payments fails
     * @param id
     * @param error
     */
    public static dispatchFetchPaymentsError(id: string, error: string) {
        EventService.dispatch(datadogLogs, {
            title: "Cancellation Email Fetch Payments Fail",
            message: `Reservation Legacy ID: ${id} `,
            type: EventTypes.CANCELLATION_EMAIL_FETCH_PAYMENTS_FAIL,
            source: EventSources.UI,
            level: EventService.ERROR_LEVEL,
            data: {id, error},
        });
    }
}
