import {configureStore} from "@reduxjs/toolkit";
import fetcherReducer from "./fetcherSlice";
import financesReducer from "./financesSlice";
import emailReducer from "./emailSlice";
import {useDispatch, useSelector, TypedUseSelectorHook} from "react-redux";

export const store = configureStore({
    reducer: {
        fetcher: fetcherReducer,
        finances: financesReducer,
        email: emailReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
