import React, {useEffect, useState} from "react";
import style from "./pills-input.module.scss";
import {Icon} from "@vacasa/react-components-lib";

interface IPillsInput {
  pillComponent: React.FC<any>;
  initialPillsProps?: Array<object>;
  pillTextField: string;
  pillCallbackDeleteField?: string;
  pillValidProps: object;
  pillInvalidProps: object;
  typeValidator?: "email";
  customValidator?: Function;
  allowDuplicated: boolean;
  callbackState?: Function;
  inputPlaceholder?: string;
}

export type PillsInputCallbackType = {
  items: Array<string>;
  error: boolean;
};

export const PillsInput: React.FunctionComponent<IPillsInput> = (props) => {
  const [inputState, setInputState] = useState({items: [], props: [], error: []});
  const [inputValue, setInputValue] = useState("");
  const [initialRender, setInitialRender] = useState(false);
  const [itemId, setItemId] = useState(0);
  const gapInputWidth = 2;
  const [inputWidth, setInputWidth] = useState(gapInputWidth);

  useEffect(() => {
    if (inputValue === "") setInputWidth(props.inputPlaceholder?.length);
    else setInputWidth(inputValue.length + gapInputWidth);
  }, [inputValue]);

  const getId = () => {
    setItemId(itemId + 1);
    return itemId + 1;
  };

  useEffect(() => {
    if (!initialRender) {
      if (props.initialPillsProps) {
        setInputState({
          items: props.initialPillsProps.map((i) => i[props.pillTextField]),
          props: props.initialPillsProps.map((e) => [getId(), e]),
          error: props.initialPillsProps.map((i) => !isValid(i[props.pillTextField])),
        });
      }
      setInitialRender(true);
    }
  }, []);

  useEffect(() => {
    if (props.callbackState)
      props.callbackState({
        items: inputState.items,
        error: inputState.error.indexOf(true) !== -1,
      });
  }, [inputState]);

  const getValidator = (type: string) => {
    if (type === "email") return (email) => /(^\S+)([\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+)/.test(email);
    return null;
  };

  const isInList = (text: string): boolean => inputState.items.includes(text);
  const isValid = (text: string): boolean => {
    if (props.customValidator) return props.customValidator(text);
    else if (props.typeValidator) return getValidator(props.typeValidator)(text);
    return true;
  };
  const isAddDuplicatedAllowed = (text: string): boolean => props.allowDuplicated || (!props.allowDuplicated && !isInList(text));

  const addItem = (text: string): void => {
    if (isAddDuplicatedAllowed(text)) {
      const valid = isValid(text);
      const newProp = valid ? {...props.pillValidProps} : {...props.pillInvalidProps};
      newProp[props.pillTextField] = text;
      setInputState({
        items: [...inputState.items, text],
        props: [...inputState.props, ...[[getId(), newProp]]],
        error: [...inputState.error, !isValid(text)],
      });
      setInputValue("");
    }
  };

  const deleteItemByIndex = (index: number) => {
    setInputState({
      ...inputState,
      items: inputState.items.filter((_, i) => i !== index),
      props: inputState.props.filter((_, i) => i !== index),
      error: inputState.error.filter((_, i) => i !== index),
    });
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    let defaultKeys = ["Enter", "Tab", ","];
    if (props.typeValidator === "email") defaultKeys.push(" ");

    if (defaultKeys.includes(evt.key)) {
      evt.preventDefault();
      let value = inputValue.trim();
      if (value) addItem(value);
    }
  };

  const handleFocusOut = (evt: React.FocusEvent<HTMLInputElement>) => {
    evt.preventDefault();
    let value = inputValue.trim();
    if (value) addItem(value);
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(evt.target.value);
  };

  const handlePaste = (evt: React.ClipboardEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const paste = evt.clipboardData.getData("text");
    if (paste) addItem(paste);
  };

  const PillComponent = props.pillComponent;

  return (
    <div className={style.pillInputFrame}>
      {inputState.props.map((prop, idx) => {
        const handleDelete = () => {
          deleteItemByIndex(idx);
        };
        const [id, componentProp] = [prop[0], prop[1]];
        if (props.pillCallbackDeleteField) componentProp[props.pillCallbackDeleteField] = handleDelete;

        return <PillComponent {...componentProp} key={id} />;
      })}

      <span className={style.inputBox}>
        <Icon.Plus className={style.iconPlus} width={18} />
        <input
          style={{width: inputWidth + "ch"}}
          autoFocus
          className={`
                    ${style.input}
                    ${style.inputStyle}
                `}
          placeholder={props.inputPlaceholder ?? ""}
          value={inputValue}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onPaste={handlePaste}
          onBlur={handleFocusOut}
        />
      </span>
    </div>
  );
};
