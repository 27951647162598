import visa from "../assets/images/visa_original.svg";
import mastercard from "../assets/images/mastercard_original.svg";
import placeholder from "../assets/images/placeholder.svg";
import {CreditCardParsed} from "@common/typing";

export const creditCardsParser = (creditsCards, totalRefund = 0): CreditCardParsed[] => {
  return creditsCards
    ?.map((card) => {
      let creditCard = card.attributes.methodType;
      let image: string;

      switch (creditCard) {
        case "VISA":
          image = visa;
          break;
        case "MC" || "MASTERCARD":
          image = mastercard;
          break;
        default:
          image = placeholder;
      }

      return {
        logo: image,
        lastDigits: card.attributes.lastFour,
        payment_date: "",
        currency: card.attributes.amount.currency,
        paid: card.attributes.amount.total,
      };
    })
    .reverse()
    .reduce(
      (acc, curr, index, array) => {
        curr.refund = acc[1] - curr.paid >= 0 ? curr.paid : acc[1] > 0 ? acc[1] : 0;
        return [array, acc[1] - curr.paid];
      },
      [[], totalRefund]
    )[0];
};
