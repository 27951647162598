import React, {ReactElement} from "react";
import {App} from "./components";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";

const CancellationEmail = (): ReactElement => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path={"/"} element={<Navigate to={"/undefined"} />} />

          {/** Main flow container **/}
          <Route path={"/:id"} element={<App />} />

          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </Router>
    </div>
  );
};

export default CancellationEmail;
