import {createSlice, createAsyncThunk, isAnyOf} from "@reduxjs/toolkit";
import {datadogLogs} from "@datadog/browser-logs";
import _ from "lodash";
import {ReservationItem, UnitItemAttributes, PaymentsSummaryItem, PaymentsItem} from "@common/typing";
import {ReservationImplicitService, ResManService, UnitImplicitService} from "@common/services";
import {RootState} from "./store";
import {EventService} from "../services";

// Service singletons
const unitService = UnitImplicitService.getInstance();
const reservationService = ReservationImplicitService.getInstance();
const resManService = ResManService.getInstance();

export const fetchAll = createAsyncThunk("fetcher/all", async (id: string, {dispatch}) => {
    try {
        const reservation: ReservationItem = await dispatch(fetchReservation(id)).unwrap();
        await Promise.all([
            dispatch(fetchUnit(reservation?.attributes.unit_id)),
            dispatch(fetchAllPayments(reservation?.attributes?.legacy_reservation_id)).unwrap(),
        ]);
        return true;
    } catch (error) {
        datadogLogs.logger.error(`[CANCELLATION-EMAIL] Error fetching data`, {error});
    }
});

export const fetchReservation = createAsyncThunk("fetcher/reservation", async (id: string, {rejectWithValue}): Promise<any> => {
    try {
        const response = await reservationService.getReservation(id, false, "guests,source");
        const guestElem = response.included?.filter((g) => g.type === "guest") || [];
        const guestInformation = {
            first_name: guestElem[0]?.attributes?.first_name,
            last_name: guestElem[0]?.attributes?.last_name,
            email: guestElem[0]?.attributes?.email,
        };
        return {
            ...response?.data[0],
            included: [...response?.included],
            attributes: {...response?.data[0].attributes, guestInformation},
        };
    } catch (error) {
        EventService.dispatchFetchReservationError(id, error.message);
        return rejectWithValue(error.message);
    }
});

export const fetchUnit = createAsyncThunk("fetcher/unit", async (uuid: string, {rejectWithValue}): Promise<any> => {
    try {
        const response = await unitService.getUnitByID(uuid);
        return response?.attributes;
    } catch (error) {
        EventService.dispatchFetchUnitError(uuid, error.message);
        return rejectWithValue(error);
    }
});

export const fetchAllPayments = createAsyncThunk("fetcher/allPayments", async (id: number, {dispatch}) => {
    const paymentsSummary = await dispatch(fetchPaymentsSummary(id)).unwrap();
    const payments = await dispatch(fetchPayments(id)).unwrap();
    return {payments, paymentsSummary};
});

export const fetchPaymentsSummary = createAsyncThunk("fetcher/paymentsSummary", async (id: number, {rejectWithValue}) => {
    try {
        return await resManService.getReservationPaymentSummary(id.toString());
    } catch (error) {
        EventService.dispatchFetchPaymentsSummaryError(id.toString(), error.message);
        return rejectWithValue(error);
    }
});

export const fetchPayments = createAsyncThunk("fetcher/payments", async (id: number, {rejectWithValue}) => {
    try {
        const response = await resManService.getPayments(id);
        response.data.forEach((data) => {
            _.remove(data.attributes.requests, (req) => req.creditAccount.deleted_at);
        });
        return response;
    } catch (error) {
        EventService.dispatchFetchPaymentsError(id.toString(), error.message);
        return rejectWithValue(error);
    }
});

export interface FetcherState {
    isLoading: boolean;
    reservation: ReservationItem;
    unit: UnitItemAttributes;
    paymentsSummary: PaymentsSummaryItem;
    payments: PaymentsItem;
    error: string | null;
}

const initialState: FetcherState = {
    isLoading: false,
    reservation: null,
    unit: null,
    paymentsSummary: null,
    payments: null,
    error: null,
};

const fetcherSlice = createSlice({
    name: "fetcher",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAll.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAll.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.error = null;
            })
            .addCase(fetchReservation.fulfilled, (state, {payload}) => {
                state.reservation = payload as any;
            })
            .addCase(fetchUnit.fulfilled, (state, {payload}) => {
                state.unit = payload as any;
            })
            .addCase(fetchPaymentsSummary.fulfilled, (state, {payload}) => {
                state.paymentsSummary = payload as any;
            })
            .addCase(fetchPayments.fulfilled, (state, {payload}) => {
                state.payments = payload as any;
            })
            .addMatcher(
                isAnyOf(fetchPayments.rejected, fetchPaymentsSummary.rejected, fetchReservation.rejected, fetchUnit.rejected),
                (state, {error}) => {
                    state.error = error.toString();
                }
            );
    },
});

export const selectFetcherReservation = (state: RootState) => state.fetcher.reservation;
export const selectFetcherUnit = (state: RootState) => state.fetcher.unit;
export const selectIsLoading = (state: RootState) => state.fetcher.isLoading;

export default fetcherSlice.reducer;
