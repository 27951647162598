import {createSlice} from "@reduxjs/toolkit";
import {fetchAllPayments} from "./fetcherSlice";
import {Finances} from "@common/utils";
import {FinancesEmailCancellationData} from "@common/typing";
import {RootState} from "./store";

const initialState: FinancesEmailCancellationData = {
    refund: {
        type: null,
        amount: 0,
        creditCards: {
            amount: 0,
            data: null,
        },
        fsc: {
            amount: 0,
            data: null,
            has: false,
        },
        check: {
            amount: 0,
        },
    },
    paid: 0,
    currencyCode: "USD",
};

const financesSlice = createSlice({
    name: "finances",
    initialState,
    reducers: {
        setFSCAmount: (state, {payload}) => {
            state.refund.fsc.amount = payload;
        },
        setFSCData: (state, {payload}) => {
            state.refund.fsc.data = payload;
        },
        setRefundAmount: (state, {payload}) => {
            state.refund.amount = payload;
        },
        setRefundType: (state, {payload}) => {
            state.refund.type = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAllPayments.fulfilled, (state, {payload}) => {
            state.refund.creditCards.data = Finances.getCreditCardsRefundData(payload.payments, payload.paymentsSummary);
            state.refund.amount = Finances.getTotalRefundAmount(payload.payments);
            state.paid = Finances.getTotalPaidAmount(payload.payments);
            state.refund.creditCards.amount = Finances.getCreditCardsRefundAmount(payload.payments);
            const fscAmount = Finances.getFSCRefundAmount(payload.payments);
            state.refund.fsc.amount = fscAmount;
            state.refund.fsc.data = Finances.getFSCRefundData(payload.payments);
            state.refund.fsc.has = fscAmount > 0;
            state.refund.check.amount = Finances.getCheckRefundAmount(payload.payments);
        });
    },
});

export const selectFinances = (state: RootState) => state.finances;
export const selectFinancesRefundType = (state: RootState) => state.finances.refund.type;
export const selectFinancesRefundAmount = (state: RootState) => state.finances.refund.amount;
export const selectFinancesRefundCreditCardsAmount = (state: RootState) => state.finances.refund.creditCards.amount;
export const selectFinancesRefundCreditCardsData = (state: RootState) => state.finances.refund.creditCards.data;
export const selectFinancesRefundCheckAmount = (state: RootState) => state.finances.refund.check.amount;
export const selectFinancesPaid = (state: RootState) => state.finances.paid;
export const selectFinancesCurrencyCode = (state: RootState) => state.finances.currencyCode;
export const selectFinancesRefundFcsData = (state: RootState) => state.finances.refund.fsc.data;
export const selectFinancesRefundFscHas = (state: RootState) => state.finances.refund.fsc.has;
export const selectFinancesRefundFscAmount = (state: RootState) => state.finances.refund.fsc.amount;

export const {setRefundType, setRefundAmount, setFSCAmount, setFSCData} = financesSlice.actions;

export default financesSlice.reducer;
