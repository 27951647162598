import "../../styles/base.module.scss";

import React, {useEffect} from "react";
import {EmailStep} from "./email-step/EmailStep";
import {ReservationDetails} from "../common-components/reservation-details/ReservationDetails";
import styles from "../app/App.module.scss";
import cx from "classnames";
import {Image} from "@vacasa/react-components-lib";
import {useAppDispatch, useAppSelector} from "../../store/store";
import {fetchAll, selectIsLoading} from "../../store/fetcherSlice";
import {useParams} from "react-router-dom";

export const App: React.FunctionComponent<any> = () => {
  const dispatch = useAppDispatch();
  const {id} = useParams<{id: string}>();
  const isLoading = useAppSelector(selectIsLoading);

  useEffect(() => {
    dispatch(fetchAll(id));
  }, []);

  return (
    <>
      {isLoading ? (
        <Image.Spinner className={styles.center} width={70} />
      ) : (
        <div className={cx("columnFlex", "innerMargin")}>
          <div className={"rowFlex"}>
            <div className={cx(styles.backTitle, "columnFlex")}>
              <h1 className={styles.title}>Cancel Reservation</h1>
            </div>
            <div className={cx(styles.reservationDetails, "rowReverseFlex")}>
              <ReservationDetails />
            </div>
          </div>
          <div>
            <EmailStep />
          </div>
        </div>
      )}
    </>
  );
};
