import "../../../../styles/base.module.scss";
import style from "./Select.module.scss";

import React from "react";

interface SelectProps {
  label?: string;
  options: Array<{text: string; id?: number | string; disabled?: boolean; hidden?: boolean}>;
  value?: string;
  onChange?: Function;
}

export const Select: React.FunctionComponent<SelectProps> = (props) => {
  const handleChange = (e) => {
    props.onChange({
      id: e.target.value,
      text: e.target.options[e.target.selectedIndex].text,
    });
  };

  return (
    <div className={"columnFlex"}>
      <div className={style.label}>{props.label && <span className={"label"}>{props.label}</span>}</div>
      {props.options && (
        <select className={[style["custom-select"], style.select].join(" ")} aria-label="" value={props.value} onChange={handleChange}>
          {props.options.map((e, index) => {
            return (
              <option key={index} value={e.id} disabled={e.disabled ?? false} hidden={e.hidden ?? false}>
                {e.text}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
};
