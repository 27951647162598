import React from "react";
import {EmailHeader} from "../email-header/EmailHeader";
import {EmailBody} from "../email-body/EmailBody";
import style from "./EmailContainer.module.scss";

export const EmailContainer: React.FunctionComponent<any> = ({data, title}) => {
  return (
    <>
      <p className={style.titleContainer}>{title}</p>
      <div className={style.emailContainer}>
        <EmailHeader data={data} />
        <EmailBody data={data} />
      </div>
    </>
  );
};
